<template>
    <div class="w-full h-full px-5 md:px-5 lg:px-10 pt-2 md:pt-5 lg:max-h-screen mb-5">
        <h1 class="primary-text font-semibold text-2xl md:text-2xl text-thin mt-2 mb-5 md:mb-7"> Estatus de solicitud </h1>
        <HistoricalLine :list="creditHistoryList"/>
    </div>
</template>

<script>
import DetailComponent  from "@/components/Cuenta/Credit/DetailComponent";
export default {
    name: 'CreditOwnerComponent',
    setup(){
        let creditHistoryList = [
            {
                title: "Solicitud enviada",
                description: "Se completó la solicitud con documentación completa y firma",
                date: "02 Dic 2022 ,16:33 hrs"
            },
            {
                title: "Solicitud en revisión",
                description: "Su solicitud se encuentra en revisión por un asesor",
                date: "03 Dic 2022 ,16:33 hrs"
            },
            {
                title: "Solicitud aceptada",
                description: "Pendiente de aceptación",
                date: "04 Dic 2022 ,16:33 hrs"
            }
        ];

        return { 
            creditHistoryList
        };
    }
}

</script>

<style scoped>
p {
    color: #485462;
}
</style>