<template>
    <div>
        <div class="bg-container col-span-6">
            <div class="w-full mx-auto h-auto grid grid-cols-1">
                <div class="">
                    <div class="border shadow rounded border-gray-100 px-5 py-4">
                        <p class="text-2xl font-bold mb-5 RobotoMedium">Datos Personales</p>  
                        
                        <div class="mb-2">
                            <p class="text-sm font-light RobotoLight">Nombre completo:</p> 
                            <p class="text-md font-light mb-2 RobotoLight">
                                {{ full_name }}
                            </p>
                        </div>
                        <div class="grid grid-cols-1">
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">R.F.C.:</p> 
                                <p class="text-md font-light RobotoLight"> {{ rfc }} </p>
                            </div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">C.U.R.P.:</p> 
                                <p class="text-md font-light RobotoLight"> {{ curp }} </p>
                            </div>
                        </div>
                        <div class="grid grid-cols-2">
                            <div class="mb-2"><p class="text-sm font-light RobotoLight">Sexo:</p> <p class="text-md font-light RobotoLight">{{ gender }} </p></div>
                            <div class="mb-2"><p class="text-sm font-light RobotoLight">Edad:</p> <p class="text-md font-light RobotoLight">{{ age }} años </p></div>
                        </div>
                        <div class="grid grid-cols-1">
                            <div class="mb-2"><p class="text-sm font-light RobotoLight">Lugar de nacimiento:</p> <p class="text-md font-light RobotoLight"> {{ birth_place }}. </p></div>
                        </div>
                        
                        <div class="mb-5 mt-5"><p class="text-lg font-light mb-5 RobotoMedium">Contacto</p></div>
                        <div class="mb-2">
                            <p class="text-sm font-light RobotoLight">Celular:</p> 
                            <p class="text-md font-light RobotoLight">{{ cellphone }}</p>
                        </div>
                        <div class="mb-2">
                            <p class="text-sm font-light RobotoLight">Teléfono:</p> 
                            <p class="text-md font-light RobotoLight">{{ telephone }}</p>
                        </div>
                        <div class="mb-2">
                            <p class="text-sm font-light RobotoLight">Correo:</p> 
                            <p class="text-md font-light RobotoLight">{{ email }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "DetailComponent",
    props: {
        rfc: {
            type: String,
            default(){
                return "---"
            }
        },
        curp: {
            type: String,
            default(){
                return "---"
            }
        },
        gender: {
            type: String,
            default(){
                return "---"
            }
        },
        age: {
            type: String,
            default(){
                return "---"
            }
        },
        birth_place: {
            type: String,
            default(){
                return "---"
            }
        },
        cellphone: {
            type: String,
            default(){
                return "---"
            }
        },
        telephone: {
            type: String,
            default(){
                return "---"
            }
        },
        email: {
            type: String,
            default(){
                return "---"
            }
        },
    },
    setup(){
        
        return {};
    }
}

</script>